<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        hide-details
        single-line
      />
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="products"
      :search="search"
    >
      <template v-slot:item.unit_price="{ value }">
        {{ value | currency }}
      </template>

      <template v-slot:item.total="{ value }">
        {{ value | currency }}
      </template>

      <template v-slot:body.append="{ headers, isMobile }">
        <tr class="total-row">
          <td
            v-if="!isMobile"
            class="text-right"
            :colspan="headers.length - 1"
          >
            <b>TOTAL</b>
          </td>
          <td :class="{ 'text-right': isMobile }">
            <b>
              <span v-if="isMobile">
                TOTAL
              </span>
              {{ orderTotal | currency }}
            </b>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'ProductList',

  props: {
    products: {
      type: Array,
      required: true,
    },

    orderTotal: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    headers: [
      { text: 'Servicio', value: 'product.service.name' },
      { text: 'Producto', value: 'product.name' },
      { text: 'Cantidad', value: 'quantity' },
      { text: 'Precio', value: 'unit_price' },
      { text: 'Total', value: 'total' },
    ],
    search: '',
  }),
};
</script>

<style lang="scss" scoped>
.total-row {
  background-color: rgba(0, 0, 0, 0.1);
}
.total-row:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
</style>
