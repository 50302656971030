<template>
  <section>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="4">
        <v-row>
          <v-col class="d-flex justify-space-between flex-wrap">
            <h1>{{ order.number | uppercase }}</h1>

            <span>
              <v-btn
                v-if="can('office_admin', 'operator') && canEditOrder"
                class="mr-3"
                color="warning"
                :to="{ name: 'order-form', params: { uuid } }"
              >
                <v-icon left>mdi-pencil</v-icon>
                Editar
              </v-btn>

              <v-btn
                class="mr-3"
                color="light-blue lighten-3"
                :to="{ name: 'order-tracking', params: { uuid } }"
              >
                <v-icon left>mdi-truck-fast</v-icon>
                Seguimiento
              </v-btn>

              <v-btn
                v-if="can('office_admin', 'operator')"
                color="error"
                @click="handleDeleteModal"
              >
                <v-icon left>mdi-delete</v-icon>
                Eliminar
              </v-btn>
            </span>
          </v-col>
        </v-row>

        <OrderGeneral :order="order" />
        <CustomerGeneral
          class="mt-3"
          :customer="order.customer"
        />
      </v-col>

      <!-- <v-divider class="my-5"></v-divider> -->

      <v-col cols="12" lg="8">
        <h3>Productos</h3>
        <ProductList
          :products="order.products"
          :order-total="order.total"
        />
      </v-col>
    </v-row>

    <Modal
      :show.sync="showDeleteModal"
      @submit="remove"
    >
      <template #title>Eliminar</template>

      <template #body>
        ¿Deseas eliminar la orden <b>{{ order.number | uppercase }}</b>?
      </template>

      <template #action>Eliminar</template>
    </Modal>
  </section>
</template>

<script>
// API
import OrderAPI from '@/api/order';

// Mixins
import DetailMixin from '@/mixins/DetailMixin';

// Components
import CustomerGeneral from './components/CustomerGeneral.vue';
import OrderGeneral from './components/OrderGeneral.vue';
import ProductList from './components/ProductList.vue';

export default {
  name: 'OrderDetail',

  props: {
    uuid: {
      type: String,
      required: true,
    },
  },

  components: {
    CustomerGeneral,
    OrderGeneral,
    ProductList,
  },

  mixins: [
    DetailMixin,
  ],

  data: () => ({
    order: {
      created_at: new Date(),
      customer: {},
      products: [],
      total: '0.00',
      updated_at: new Date(),
    },
    listRouteName: 'order-list',
  }),

  computed: {
    breadcrumbs() {
      const name = this.order.number || '';
      return [
        {
          text: 'Órdenes',
          disabled: false,
          exact: true,
          to: { name: 'order-list' },
        },
        {
          text: name.toUpperCase(),
          disabled: true,
        },
      ];
    },

    canEditOrder() {
      if (!this.order.status) return false;

      const allowedStatus = ['Asignada', 'Creada', 'En camino a recolección'];

      return !this.order.paid && allowedStatus.includes(this.order.status);
    },
  },

  async created() {
    this.api = new OrderAPI();
    await this.detail();
  },

  methods: {
    async detail() {
      this.$store.commit('showLoader');
      try {
        this.order = await this.api.detail(this.uuid);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
